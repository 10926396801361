import { createContext, FC, useContext, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAsync } from 'uns-common-react';
import { UserSummary } from 'uns-common';
import { account } from '../common/api';

const UserContext = createContext(
	undefined as unknown as {
		user: Required<UserSummary>;
		refresh: () => Promise<void>;
	}
);
export const useUser = () => useContext(UserContext);

export const Authenticated: FC<{ component: FC }> = (props) => {
	const deviceToken = localStorage.getItem('deviceToken');
	if (!deviceToken) return <RegisterDevice {...props} />;
	return <UserComponent {...props} />;
};

const RegisterDevice: FC<{ component: FC }> = (props) => {
	const {
		loading,
		data: deviceToken,
		error,
	} = useAsync(account.registerDevice, [], true);

	if (loading) return <Alert variant="light">Registering device...</Alert>;
	if (error) return <Alert variant="danger">{error.message}</Alert>;
	if (!deviceToken) return <>No Device Token</>; // This should never occur

	localStorage.setItem('deviceToken', deviceToken);
	return <UserComponent {...props} />;
};

const UserComponent: FC<{ component: FC }> = (props) => {
	const {
		loading,
		data: user,
		error,
		execute: refresh,
	} = useAsync(
		async () => (await account.getUser()) as Required<UserSummary>,
		[],
		true
	);
	const [value] = useState({
		user: undefined as unknown as Required<UserSummary>,
		refresh,
	});

	if (user) {
		value.user = user;
		if (!value.refresh) value.refresh = refresh;
		return (
			<UserContext.Provider value={value}>
				<props.component />
			</UserContext.Provider>
		);
	}
	if (loading) return <Alert variant="light">Logging in...</Alert>;
	if (error) return <RegisterDevice {...props} />;

	return <>No User</>; // This should never occur
};

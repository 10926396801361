import { useState } from 'react';
import { useUser } from '../authenticated';
import { GuardianAuthTicket } from 'uns-common';
import { Approval } from './approval';

export const DomainApprovals: React.FC<{
	domain: string;
	tickets: GuardianAuthTicket[];
}> = ({ domain, tickets }) => {
	const {
		user: { devices, currentDevice },
	} = useUser();
	const allDevices = [currentDevice, ...devices];
	const findDevice = (id?: string) => {
		return allDevices.find((device) => device.id === id);
	};
	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpansions = () => {
		setIsExpanded(!isExpanded);
	};
	return (
		<div>
			<div
				className={'bg-white border rounded p-2'}
				style={{ cursor: 'pointer' }}
				onClick={toggleExpansions}
			>
				<p className="m-0">{domain}</p>
			</div>
			{isExpanded && (
				<div className="p-3 bg-light rounded mt-2 d-flex flex-column gap-2">
					{tickets.map((ticket, i) => (
						<Approval
							ticket={ticket}
							device={findDevice(ticket.allowedDeviceId)}
							key={i}
						/>
					))}
				</div>
			)}
		</div>
	);
};

import {
	DependencyList,
	useCallback,
	useEffect,
	useState,
	Dispatch,
} from 'react';

interface UseAsyncResultBase<D, E> {
	loading?: boolean;
	success?: boolean;
	data?: D;
	error?: E;
}

interface UseAsyncResult<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	F extends (...args: any[]) => Promise<D>,
	D = Awaited<ReturnType<F>>,
	E = Error
> extends UseAsyncResultBase<D, E> {
	execute: (...args: Parameters<F>) => Promise<void>;
}

/**
 * Returns an stateful asynchronous function execution
 * @param asyncFunction What async function co call
 * @param deps Dependencies
 * @param immediate Whether or not to immediately run the async function
 * @param clearStateOnExecute Clears state after function execution
 * @returns The execute function, state of the function and data returned from the data execution
 */
export const useAsync = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	F extends (...args: any[]) => Promise<D>,
	D = Awaited<ReturnType<F>>,
	E = Error
>(
	asyncFunction: F,
	deps: DependencyList = [],
	immediate = false,
	clearStateOnExecute = false
): UseAsyncResult<F, D, E> => {
	const {
		loading,
		success,
		data,
		error,
		setLoading,
		setData,
		setError,
		clear,
	} = useAsyncState<D, E>();

	const execute = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (...args: any[]) => {
			setLoading(true);

			if (clearStateOnExecute) clear();

			try {
				setData(await asyncFunction(...args));
			} catch (error) {
				setError(error as E);
			}
		},
		[setLoading, clearStateOnExecute, setData, setError, asyncFunction, ...deps] // eslint-disable-line react-hooks/exhaustive-deps
	);

	useEffect(() => {
		if (immediate && loading === undefined) execute();
	}, [immediate, loading, execute]);

	return { execute, loading, success, data, error };
};

interface UseAsyncResultState<D, E> extends UseAsyncResultBase<D, E> {
	setLoading: Dispatch<React.SetStateAction<boolean | undefined>>;
	setData: (data: D) => void;
	setError: (error: E) => void;
	clear: () => void;
}

const useAsyncState = <D, E>(): UseAsyncResultState<D, E> => {
	const [loading, setLoading] = useState<boolean>();
	const [success, setSuccess] = useState<boolean>();
	const [data, setDataInternal] = useState<D>();
	const [error, setErrorInternal] = useState<E>();

	const setData = useCallback(
		(data: D) => {
			setLoading(false);
			setSuccess(true);
			setDataInternal(data);
			setErrorInternal(undefined);
		},
		[setLoading, setSuccess, setDataInternal, setErrorInternal]
	);

	const setError = useCallback(
		(error: E) => {
			setLoading(false);
			setSuccess(false);
			setDataInternal(undefined);
			setErrorInternal(error);
		},
		[setLoading, setSuccess, setDataInternal, setErrorInternal]
	);

	const clear = useCallback(() => {
		setSuccess(undefined);
		setDataInternal(undefined);
		setErrorInternal(undefined);
	}, [setSuccess, setDataInternal, setErrorInternal]);

	return {
		loading,
		success,
		data,
		error,
		setLoading,
		setData,
		setError,
		clear,
	};
};

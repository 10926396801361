import {
	AuthenticationCredential,
	PublicKeyCredentialCreationOptionsJSON,
	RegistrationCredential,
} from '@simplewebauthn/typescript-types';
import { get, httpDelete, post, put } from 'uns-common-react';
import {
	AttestationType,
	GuardianAuthTicketByService,
	UserSummary,
} from 'uns-common';

const baseUrl = `${process.env.REACT_APP_API_HOST ?? ''}/api`;
const accountBaseUrl = `${baseUrl}/account`;
const ticketBaseUrl = `${baseUrl}/ticket`;

export const account = {
	/**
	 * Registers device on the Guardian
	 * @returns ID of the device
	 */
	registerDevice: function (): Promise<string> {
		return post(`${accountBaseUrl}/registerDevice`);
	},

	/**
	 * Gets user data from the Guardian
	 * @returns User data
	 */
	getUser: async function (): Promise<UserSummary> {
		const user = await get<UserSummary>(`${accountBaseUrl}/user`, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});

		if (user.currentDevice)
			user.currentDevice.createdAt = new Date(user.currentDevice.createdAt);

		if (user.devices)
			user.devices.forEach((device) => {
				device.createdAt = new Date(device.createdAt);
			});

		return user;
	},

	/**
	 * Call the Guardian API to send an email to the User
	 * @param email email of the user from the form data
	 * @param nonce nonce of the ticket?
	 * @returns
	 */
	sendVerificationEmail: function (
		email: string,
		nonce?: string
	): Promise<void> {
		let url = `${accountBaseUrl}/sendVerificationEmail/${email}`;
		if (nonce) url += `/${nonce}`;
		return put(url, undefined, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},

	/**
	 * Verifies the user via email on the Guardian
	 * @param verificationCode verifcation code from the email
	 * @param hash hash value of the email
	 * @param nonce nonce of the ticket?
	 */
	verifyEmail: function (verificationCode: string, nonce?: string) {
		let url = `${accountBaseUrl}/verifyEmail/${verificationCode}`;
		if (nonce) url += `/${nonce}`;
		return put(url, undefined, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},

	/**
	 * Calls the Guardian API to initialize Web authentication
	 *
	 */
	initalizeWebAuthnRegistration:
		function (): Promise<PublicKeyCredentialCreationOptionsJSON> {
			const url = `${accountBaseUrl}/initalizeWebAuthnRegistration`;
			return post(url, undefined, {
				authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
			});
		},

	/**
	 * Calls the Guardian API to finalize the Web authentication registration
	 * @param credential credentials of the physical security key
	 * @param nonce nonce of the ticket?
	 *
	 */
	finalizeWebAuthnRegistration: function (
		credential: RegistrationCredential,
		nonce?: string
	): Promise<PublicKeyCredentialCreationOptionsJSON> {
		const url = `${accountBaseUrl}/finalizeWebAuthnRegistration`;
		return post(
			url,
			{ credential, nonce },
			{
				authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
			}
		);
	},

	/**
	 * Calls the Guardian API to initialize the web authentication on the Guardian
	 */
	initalizeWebAuthnAuthentication:
		function (): Promise<PublicKeyCredentialCreationOptionsJSON> {
			const url = `${accountBaseUrl}/initalizeWebAuthnAuthentication`;
			return post(url, undefined, {
				authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
			});
		},

	/**
	 *
	 * @param credential Credentials of the physical security key
	 * @param nonce nonce of the ticket
	 * @returns
	 */
	finalizeWebAuthnAuthentication: function (
		credential: AuthenticationCredential,
		nonce?: string
	): Promise<PublicKeyCredentialCreationOptionsJSON> {
		const url = `${accountBaseUrl}/finalizeWebAuthnAuthentication`;
		return post(
			url,
			{ credential, nonce },
			{
				authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
			}
		);
	},

	/**
	 * Method for logging out a device from the user's account
	 */
	logout: function () {
		const url = `${accountBaseUrl}/logout`;
		return post(url, undefined, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},

	/**
	 * Deletes the UNS account and all devices associated with it
	 * @param email email associated with the UNS account (Optional)
	 */
	deleteUNSAccount: function () {
		const url = `${accountBaseUrl}/deleteUNSAccount`;
		return httpDelete(url, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},

	/**
	 * Deletes the non-current device from the user
	 * @param deviceId ID of the device
	 */
	deleteDevice: function (deviceId: string) {
		const url = `${accountBaseUrl}/deleteUNSDevice/${deviceId}`;
		return httpDelete(url, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},
};

export const ticket = {
	/**
	 * Calls the Guardian API to get the ticket for the user from the specified Gatekeeper
	 * @param nonce nonce of the ticket
	 * @param gatekeeperId On what Gatekeeper the Guardian should get the ticket from
	 * @returns returns the Domain and the Required attestation type from the ticket
	 */
	getTicket: function (
		nonce: string,
		gatekeeperId: string
	): Promise<{ domain: string; attestationTypeRequired: AttestationType }> {
		return get<{ domain: string; attestationTypeRequired: AttestationType }>(
			`${ticketBaseUrl}/${nonce}/${gatekeeperId}`,
			{ authorization: `Bearer ${localStorage.getItem('deviceToken')}` }
		);
	},

	/**
	 * Calls the Guardian API to authenticate user's ticket.
	 * @param nonce Nonce of the ticket
	 */
	authenticateTicket: function (nonce: string): Promise<void> {
		return put(`${ticketBaseUrl}/authenticate/${nonce}`, undefined, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},
	/**
	 * Gets the ticket saved on the Guardian
	 * @param nonce Nonce of the ticket
	 * @returns Ticket saved on the guardian
	 */
	getGuardianTicket: function (
		nonce: string
	): Promise<{ domain: string; attestationTypeRequired: AttestationType }> {
		return get<{ domain: string; attestationTypeRequired: AttestationType }>(
			`${ticketBaseUrl}/${nonce}`,
			{ authorization: `Bearer ${localStorage.getItem('deviceToken')}` }
		);
	},
	/**
	 * Gets tickets grouped by the domain
	 * @returns User's tickets grouped by domain
	 */
	getUserTickets: function (): Promise<GuardianAuthTicketByService> {
		return get<GuardianAuthTicketByService>(`${ticketBaseUrl}/getUserTickets`, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},
	/**
	 *
	 * @param nonce Nonce of the association ticket
	 * @param once Set to true if we do not want to associate the device with the authenticator
	 * @returns QR code image
	 */
	getAssociationTicket: async function (
		nonce?: string,
		once?: boolean
	): Promise<string> {
		return post(
			`${ticketBaseUrl}/createAssociationTicket`,
			{ nonce, once },
			{ authorization: `Bearer ${localStorage.getItem('deviceToken')}` }
		);
	},
	/**
	 * Verifies if the authenticator and device are of the same User
	 * @param nonce nonce of the ticket
	 */
	verifyTicket: async function (nonce: string) {
		return put(`${ticketBaseUrl}/verify/${nonce}`, undefined, {
			authorization: `Bearer ${localStorage.getItem('deviceToken')}`,
		});
	},
};

import {
	AttestationTypeDescription,
	Device,
	GuardianAuthTicket,
} from 'uns-common';
import { Check2Circle } from 'react-bootstrap-icons';
export const Approval: React.FC<{
	ticket: GuardianAuthTicket;
	device?: Device;
}> = ({ ticket, device }) => {
	const OS = device?.OS?.toLowerCase();
	const deviceOS =
		OS === 'ios' ? 'iPhone' : OS === 'android' ? 'Android' : device?.osName;
	const deviceApp =
		OS === 'ios' || OS === 'android'
			? 'UNS Authenticator'
			: device?.browserName;
	return (
		<>
			<div className={'bg-white border rounded p-2'} style={{ marginTop: 5 }}>
				<div className="d-flex align-items-center justify-content-between">
					<div>
						<small className="text-grey">
							{ticket.authTime &&
								new Date(ticket.authTime.toString()).toLocaleString()}
						</small>
						<strong className="d-block">
							{deviceOS} | {deviceApp}
						</strong>
					</div>
					<div style={{ flexDirection: 'row' }}>
						<strong style={{ marginRight: 5 }}>
							{AttestationTypeDescription[ticket.attestationTypeRequired]}
						</strong>
						<Check2Circle color="green" />
					</div>
				</div>
				<hr className="my-1" />
				<small>{`Authenticated to ${ticket.domain} on ${deviceOS} using ${deviceApp}`}</small>
			</div>
		</>
	);
};

import { BaseLogger, MessageOrPayload } from './base-logger';
import { LogLevel } from './log-level';

export class ConsoleLogger extends BaseLogger {
	constructor(private readonly tag: string) {
		super();
	}

	override async log<T extends MessageOrPayload>(
		messageOrPayload: T,
		logLevel = this.defaultLogLevel
	): Promise<void> {
		const payload = {
			...this.format(messageOrPayload),
			logLevel,
			tag: this.tag,
		};

		switch (logLevel) {
			case LogLevel.ERROR:
				console.error(payload);
				break;
			case LogLevel.WARN:
				console.warn(payload);
				break;
			case LogLevel.INFO:
				console.info(payload);
				break;
			case LogLevel.DEBUG:
				console.debug(payload);
				break;
			default:
				console.log(payload);
		}
	}
}

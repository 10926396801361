import { Request as RouterRequest } from 'itty-router';
import {
	AuthTicketIdenfifier,
	Guardian,
	Service,
	ServiceIdentifier,
	User,
} from '../contracts';

export * from './apiError';
export * from './applyMiddleware';
export * from './http';

export const API_BASE = '/api';

export type ApiRequest = RouterRequest & {
	hostname: string;
	origin: string;
	pathname: string;
	requestId: string;
	headers: Headers;
	text: () => Promise<string>;
};

export type JsonApiRequest<TBody, TReq extends ApiRequest = ApiRequest> = Omit<
	TReq,
	'json'
> & {
	json: () => Promise<TBody>;
};

export type ParamsApiRequest<
	TParams,
	TReq extends ApiRequest = ApiRequest
> = Omit<TReq, 'params'> & {
	params: TParams;
};

export type DataApiRequest<
	TData,
	TReq extends ApiRequest = ApiRequest
> = TReq & { data: TData };

export type ServiceApiRequest = ApiRequest & { service: Service };
export type GuardianApiRequest = ApiRequest & { guardian: Guardian };
export type UserApiRequest = ApiRequest & { user: Required<User> };

export type ParamsServiceApiRequest = ParamsApiRequest<
	ServiceIdentifier,
	ServiceApiRequest
>;
export type ParamsTicketApiRequest = ParamsApiRequest<AuthTicketIdenfifier>;

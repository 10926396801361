import { FC } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useAsync } from 'uns-common-react';
import { ticket } from '../../common/api';
import { ModalHeader } from '../modalHeader';

export const ProcessTicketModal: FC<{
	nonce: string;
	domain: string;
	closeModal: () => void;
}> = ({ nonce, domain, closeModal }) => {
	const {
		loading,
		error,
		success,
		execute: authenticateTicket,
	} = useAsync(() => ticket.authenticateTicket(nonce), [nonce]);

	return (
		<Modal show onHide={closeModal}>
			<ModalHeader />
			<Modal.Body>
				{loading === undefined && (
					<>
						Log in to <strong>{domain}</strong> ?
					</>
				)}
				{loading && (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" variant="primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				)}
				{error && (
					<>
						Service <strong>{domain}</strong> could not be authenticated.
						<br />
						{error.message}
					</>
				)}
				{success && (
					<>
						Service <strong>{domain}</strong> authenticated.
						<br />
						<strong>{domain}</strong> will be updated in your browser.
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				{loading === undefined && (
					<Button onClick={authenticateTicket}>Confirm</Button>
				)}
				<Button onClick={closeModal}>
					{loading === undefined ? 'Cancel' : 'Close'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

import { FC } from 'react';
import './deviceVerificationViaPhoneModal.scss';
import { QRCodeModal } from './QRCodeModal';
import { ticket } from '../../common/api';
import { useAsync } from 'uns-common-react';
import { Spinner } from 'react-bootstrap';

export const DeviceVerificationViaPhoneModal: FC<{
	nonce?: string;
	closeModal: () => void;
}> = ({ nonce, closeModal }) => {
	const { loading, data, error } = useAsync(
		(nonce?: string, once?: boolean) =>
			ticket.getAssociationTicket(nonce, once),
		[nonce],
		true
	);

	if (loading) {
		return (
			<>
				<Spinner animation="border" variant="primary" role="status" />
				<p>Loading QR Code</p>
			</>
		);
	}
	return (
		<QRCodeModal
			assocTicket={data}
			closeModal={closeModal}
			once={false}
			error={error?.message}
		/>
	);
};

import { FC, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { AttestationTypeDescription, Device as DeviceModel } from 'uns-common';
import { AccountDeleteDeviceModal } from './accountDeleteDeviceModal';

export const Device: FC<{
	device: DeviceModel;
	current?: boolean;
	className?: string;
	removeDevice?: () => void;
}> = ({
	device: { id, osName, browserName, attestationType, createdAt, OS },
	current,
	className,
	removeDevice,
}) => {
	const deviceOS = OS === 'ios' ? 'iPhone' : osName;
	const deviceApp = OS === 'ios' ? 'UNS Authenticator' : browserName;

	const [showModal, setShowModal] = useState(false);
	const toggleModal = useCallback(() => {
		setShowModal((val) => !val);
	}, [setShowModal]);

	return (
		<>
			<AccountDeleteDeviceModal
				showDeleteDevice={showModal}
				closeModal={toggleModal}
				confirmDelete={removeDevice}
			/>
			<div
				className={`bg-white border rounded p-2 ${
					className ? ' ' + className.trim() : ''
				}`.trim()}
			>
				<div className="d-flex align-items-center justify-content-between">
					<div>
						<small className="text-grey">{createdAt.toLocaleString()}</small>
						<strong className="d-block">
							{deviceOS} | {deviceApp}
						</strong>
					</div>
					{!current && (
						<Button variant="link" onClick={toggleModal}>
							<Trash />
						</Button>
					)}
				</div>
				<hr className="my-1" />
				<small>{AttestationTypeDescription[attestationType]}</small>
			</div>
		</>
	);
};

import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { ActionButton, CountdownMessage, useAsync } from 'uns-common-react';
import { account } from '../../common/api';
import { useUser } from '../authenticated';
import { ModalHeader } from '../modalHeader';

export const DeviceVerificationViaEmailModal: FC<{
	nonce?: string;
	closeModal: () => void;
}> = ({ nonce, closeModal }) => {
	const [email, setEmail] = useState('');
	const [validated, setValidated] = useState<boolean>();
	const [message, setMessage] = useState<string>();
	const { refresh } = useUser();

	const {
		loading,
		error,
		success,
		execute: sendVerificationEmail,
	} = useAsync(account.sendVerificationEmail);

	const onSubmitClick = useCallback(() => setValidated(true), []);
	const onFormSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (event.currentTarget.checkValidity() === true) {
				await sendVerificationEmail(email, nonce);
				setMessage(
					'An email has been sent.\nPlease verify your device by opening link in the email.'
				);
			}

			return false;
		},
		[email, sendVerificationEmail, nonce]
	);

	const afterSuccessMessage = useCallback(() => {
		if (nonce) {
			window.close();
		} else {
			closeModal();
			refresh();
		}
	}, [nonce, closeModal, refresh]);

	// reset validated flag on input change
	useEffect(() => setValidated(false), [email]);

	return (
		<Modal show onHide={closeModal}>
			<Form validated={validated} onSubmit={onFormSubmit}>
				<ModalHeader />
				<Modal.Body className="d-flex flex-column">
					<h6 className="mb-2">Verify by email</h6>

					{!success && (
						<Form.Control
							value={email}
							placeholder="Enter your email"
							type="email"
							name="email"
							required
							onChange={({ target: { value } }) => setEmail(value)}
						/>
					)}

					{success && message && (
						<Alert variant="success">
							<div style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
							<small>
								<CountdownMessage
									className="mt-2"
									format={`This ${
										nonce ? 'window' : 'popup'
									} will be automatically closed in {0} seconds.`}
									start={10}
									endAction={afterSuccessMessage}
								/>
							</small>
						</Alert>
					)}
				</Modal.Body>
				{!success && (
					<Modal.Footer>
						<ActionButton
							type="submit"
							loading={loading}
							error={error?.message}
							reverse
							onClick={onSubmitClick}
						>
							Verify
						</ActionButton>
					</Modal.Footer>
				)}
			</Form>
		</Modal>
	);
};
